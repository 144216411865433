<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Header"
                  v-model="main.intro.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Subheader"
                  v-model="main.intro.subheader"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Desc"
                  v-model="main.intro.desc"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image Main *">
                  <div v-if="imgIntroExisting">
                    <a style="display: block;" target="_blank" :href="imgIntroExisting"><img style="max-width: 400px;" :src="imgIntroExisting"></a>
                    <div><va-button small color="danger" @click="imgIntroExisting = deleteImage(imgId) ? '' : imgIntroExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="main.intro.img"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(main.intro.img[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Ceo Header"
                  v-model="main.ceo.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Ceo Subheader"
                  v-model="main.ceo.subheader"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Ceo Desc"
                  v-model="main.ceo.desc"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image Ceo *">
                  <div v-if="imgCeoExisting">
                    <a style="display: block;" target="_blank" :href="imgCeoExisting"><img style="max-width: 400px;" :src="imgCeoExisting"></a>
                    <div><va-button small color="danger" @click="imgCeoExisting = deleteImage(imgId) ? '' : imgCeoExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="main.ceo.img"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(main.ceo.img[0],'imgCeoExisting')">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="Image Mission *">
                  <div v-if="imgMissionExisting">
                    <a style="display: block;" target="_blank" :href="imgMissionExisting"><img style="max-width: 400px;" :src="imgMissionExisting"></a>
                    <div><va-button small color="danger" @click="imgMissionExisting = deleteImage(imgId) ? '' : imgMissionExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="main.mission.img"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(main.mission.img[0],'imgMissionExisting')">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="Image principles *">
                  <div v-if="imgPrinciplesExisting">
                    <a style="display: block;" target="_blank" :href="imgPrinciplesExisting"><img style="max-width: 400px;" :src="imgPrinciplesExisting"></a>
                    <div><va-button small color="danger" @click="imgPrinciplesExisting = deleteImage(imgId) ? '' : imgPrinciplesExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="main.mission.img"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(main.principles.img[0],'imgPrinciplesExisting')">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="Image Values *">
                  <div v-if="imgValuesExisting">
                    <a style="display: block;" target="_blank" :href="imgValuesExisting"><img style="max-width: 400px;" :src="imgValuesExisting"></a>
                    <div><va-button small color="danger" @click="imgValuesExisting = deleteImage(imgId) ? '' : imgValuesExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="main.mission.img"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(main.values.img[0],'imgValuesExisting')">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Link name"
                  v-model="main.intro.link_name"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Link href"
                  v-model="main.intro.link_href"
                  :messages="['The recommended number of characters is 15']"
                />
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Header"
                  v-model="main.history.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-data-table
                  :fields="tabFields"
                  :data="history"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished('quotes',props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-capital-page-about-item', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Header"
                  v-model="main.mission.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Subheader"
                  v-model="main.mission.subheader"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-data-table
                  :fields="tabFields"
                  :data="missions"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished('missions',props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-capital-page-about-item', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-input
                  label="Header"
                  v-model="main.principles.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Subheader"
                  v-model="main.principles.subheader"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-data-table
                  :fields="tabFields"
                  :data="principles"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished('principles',props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-capital-page-about-item', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 4">
                <va-input
                  label="Header"
                  v-model="main.values.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Subheader"
                  v-model="main.values.subheader"
                  :messages="['The recommended number of characters is 15']"
                />
              </div>
              <va-button @click="submit()">Save</va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
// import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    // ckeditor: CKEditor.component,
  },
  data () {
    return {
      main: {
        ceo: {},
        history: {},
        intro: {
          desc: null,
          header: null,
          img: null,
          link_href: null,
          link_name: null,
          subheader: null,
        },
        mission: {},
        principles: {},
        values: {},
      },
      history: null,
      missions: null,
      principles: null,

      imgIntroExisting: null,
      imgCeoExisting: null,
      imgMissionExisting: null,
      imgPrinciplesExisting: null,
      imgValuesExisting: null,

      imgIntroNew: null,
      imgCeoNew: null,
      imgMissionNew: null,
      imgPrinciplesNew: null,
      imgValuesNew: null,

      header: '',
      subheader: '',
      desc: '',
      link_name: '',
      link_href: '',
      text_contents_text: '',
      trailingText: '',
      stats_text: '',
      numList: null,
      quotes: null,
      stats: null,
      companyContents: null,

      img: [],
      imgId: '',
      imgExisting: '',
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      content: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main', 'History', 'Missions', 'Principles', 'Values'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    uploadImage (file, tab = 'imgIntroExisting') {
      console.log(file)
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          if (tab === 'imgIntro') {
            this.imgIntroNew = response.data.patch
          }
          if (tab === 'imgCeoExisting') {
            this.imgCeoNew = response.data.patch
          }
          if (tab === 'imgMissionExisting') {
            this.imgMissionNew = response.data.patch
          }
          if (tab === 'imgPrinciplesExisting') {
            this.imgPrinciplesNew = response.data.patch
          }
          if (tab === 'imgValuesExisting') {
            this.imgValuesNew = response.data.patch
          }
          this.imgId = response.data.id
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      this.main.intro.img = this.imgIntroNew
      this.main.ceo.img = this.imgCeoNew
      this.main.mission.img = this.imgMissionNew
      this.main.principles.img = this.imgPrinciplesNew
      this.main.values.img = this.imgValuesNew
      const data = {
        main: this.main,
      }
      axios.put(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/about`, data)
        .then(response => {
          this.$router.push({ name: 'ms-capital-page-about' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/about`)
        .then(response => {
          console.log(response.data)
          this.main = response.data.main
          this.history = response.data.history
          this.missions = response.data.missions
          this.principles = response.data.principles
          this.imgIntroNew = response.data.main.intro.img
          this.imgCeoNew = response.data.main.ceo.img
          this.imgMissionNew = response.data.main.mission.img
          this.imgPrinciplesNew = response.data.main.principles.img
          this.imgValuesNew = response.data.main.values.img

          this.imgIntroExisting = process.env.VUE_APP_MS_URL + response.data.main.intro.img
          this.imgCeoExisting = process.env.VUE_APP_MS_URL + response.data.main.ceo.img
          this.imgMissionExisting = process.env.VUE_APP_MS_URL + response.data.main.mission.img
          this.imgPrinciplesExisting = process.env.VUE_APP_MS_URL + response.data.main.principles.img
          this.imgValuesExisting = process.env.VUE_APP_MS_URL + response.data.main.values.img
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
  computed: {
    tabFields () {
      return [
        {
          name: 'key',
          title: 'Key',
          width: '40%',
        },
        {
          name: 'content',
          title: 'Content',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
